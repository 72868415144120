/* tablet */
@media screen and (max-width: 992px) {
    .root-container {
        display: flex;
        flex-direction: column;
    }

    /* search route section + search btn */
    .home-search-container {
        position: fixed !important;
        height: auto !important;
    }

    /* top search bar */
    .search-container {
        flex-wrap: wrap;
    }

    .search-container .top-title {
        display: none;
    }

    /* seacrh bar */
    .route-block-route-types {
        width: 50% !important;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-route-types div:nth-child(2) span {
        border-radius: 0px 5px 5px 0px !important;
    }

    .route-block-inputs {
        width: 100% !important;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-inputs {
        /* border-radius: 5px 0px 0px 5px !important; */
    }

    .route-block-date-dropdown {
        width: 100% !important;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .route-block-date-dropdown>span>div {}

    /* all route section upto search btn */
    .route-block {
        /* display: block !important; */
        /* height: 100% !important; */

        /* border: 1px solid orange; */
    }

    /* search btn */
    .route-block-btn {
        margin: 0px auto !important;
        /* border: 1px solid red; */
        width: 30%;
        padding: 10px 0;
    }


    /* result */
    .home-result-container {
        padding-top: 225px !important;
        width: 100% !important;

    }

    .home-result-parent {
        flex-direction: column;
        width: 100% !important;

        align-items: center;
    }

    .home-result-side-component {
        margin-right: 0 !important;
    }

    .fcl-container-type {
        width: 100% !important;
    }

    .role-container-type {
        width: 100% !important;
    }

    .fcl-schedules-filter-container-type {
        width: 100% !important;
    }

    .lcl-slider {
        width: 100% !important;
    }

    /* no-result card */
    .no-result {
        /* padding: 125px 10px 10px 10px !important; */
        padding: 10px 10px 10px 10px !important;
    }

    .no-result-card {
        /* margin-top: 170px !important; */
        margin-top: 10px !important;
        height: auto !important;
    }

    /* no-start card */
    .no-start {
        padding: 20px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 100px;
    }

    .no-start-card {
        margin-top: 170px !important;
        height: auto !important;
    }
}

/* @media screen and (max-width: 675px) {
    .main-card {
        width: 100% !important;
        margin: inherit auto !important;
    }
} */

/* shift book-btn below route block 650px*/
@media screen and (max-width: 700px) {

    .home-result-container {
        /* padding-top: 205px !important; */
        padding: 225px 10px 10px 10px !important;
    }

    .home-result-parent {
        /* margin-top: 30px !important; */
        /* background: red; */
    }

    .no-result {
        padding: 10px 10px 10px 10px !important;
    }

    .no-result-card {
        margin-top: 10px !important;
        height: auto !important;
    }

     /* no-start card */
     .no-start {
        padding: 125px 10px 10px 10px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 100px;
    }

    .no-start-card {
        margin-top: 70px !important;
        height: auto !important;
    }

    /* result */
    .fcl-container-type {}
    
    .role-container-type {}

    .home-result-parent {}

    .main-card {
        width: 100% !important;
    }

    .block-items-left {
        width: 100% !important;
    }

    .block-items-right {
        width: 100% !important;
    }

    /* for company name block */
    .company-name-block .company-name-iner {
        max-width: 100% !important;
    }
    .schedule-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }
}



/* mobile */
@media screen and (max-width: 576px) {
    .home-result-parent {
        margin-top: -30px !important;
        /* background: red; */
    }

    .home-result-side-component {
        padding-top: 0 !important;
        margin-top: 55px;
    }

    /* top search bar */
    .search-container .top-title {
        display: none;
    }

    .home-search-container {
        position: relative !important;
        height: auto;
    }

    .home-result-container {
        padding-top: 0px !important;
    }

    .route-block-btn .search-btn {
        padding: 0px 5px !important;
    }

    /* view-details extra info, ocean-freight */
    .vd-extra-info {
        display: none;
    }

    /* pickup-delivery-line */

    /*view-details pickup-delivery-ers radio-group line  */
    /* option name */
    .search-radio-group-pde-line.ant-radio-group label>span:nth-child(2)>span:nth-child(1) {
        max-width: 140px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding-bottom: -12px;
    }


    /* no-result card */
    .no-result {
        padding: 10px !important;
    }

    .no-result-card {
        margin-top: 20px !important;
        height: auto !important;
    }

    /* no-start card */
    .no-start {
        padding: 20px !important;
        position: absolute !important;
        margin: 0 auto !important;
        /* margin-top: 200px !important; */
        left: 10px;
        top: 250px;
    }

    .no-start-card {
        height: auto !important;
    }

    .schedule-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) {

    .home-result-side-component {
        padding-right: 20px !important;
        margin-top: 55px;
    }
    .schedule-info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }
}