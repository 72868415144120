.testR {
  border: 1px dotted red;
}

.testO {
  border: 1px dotted orange;
}

.testPur {
  border: 1px dotted rgb(12, 11, 12);
}

.testBlu {
  border: 1px dotted blue;
}

.testY {
  border: 1px dotted yellow;
}

.testBla {
  border: 1px dotted black;
}

.main-card {
  width: 675px;
  height: auto;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  background: white;
}

/* **********start of TOP section************** */

/* *********************start of LEFT block in TOP section********************* */
/* company name, chat, route */

.block-items-left {
  flex-grow: 1;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

/* 
company-name-block
*/
.company-name-block {
  border-bottom: 1px solid #eff3f7;
  /* border-right: 1px solid #eff3f7; */
  padding: 15px 10px 14px 10px;
  width: 100%;
  height: auto;
  min-height: 80px;
}

.company-info {
  display: block;
  font-weight: 500;
  /* color: #43637c; */
  /* font-size: 14px; */
  /* text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  /* min-width: 230px; */
  /* max-width: 370px; */
  width: 100%;
}

/* .company-info:hover {
  text-decoration: underline;
} */

.rate-stars {
  width: 85px;
  height: 24px;
  margin-left: 3.5px;
}

/* .my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-full i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-full i svg:hover {
  transform: scale(1);
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-zero i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
}

.my-rate-stars-21a3741 .ant-rate-star.ant-rate-star-half.ant-rate-star-active i svg {
  width: 15px;
  height: 20px;
  margin: -3.5px;
} */

/* .open-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #008cde;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  border-radius: 36px;
  text-transform: uppercase;
  color: #008de0;
  padding: 3px 17px;
  transition: color 0.3s;
}

.open-chat:hover {
  background-color: #f0fafe;
  border: 1px solid #aad8ff;
} */

/* 
route-time-block
*/
.route-time-block {
  padding: 20px 20px 32px 20px;
  /* border-right: 1px solid #eff3f7; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.route {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.route .line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: relative;
  margin-bottom: 10px;
}

.route .line > i:first-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

.route .line > i:last-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}
.route-data {
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  z-index: 2;
  /* margin-left: 2.5rem;
  margin-top: -5px; */
  margin-left: 2rem;
  margin-top: -10px;
  width: 150px;
}

.route-time-block-vertical {
  padding: 20px 20px 32px 20px;
  /* border-right: 1px solid #eff3f7; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.route-small-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.route-small-screen .line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  position: relative;
  margin-bottom: 10px;
}

.route-small-screen .route-icon > i:first-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

.route-small-screen .route-icon > i:last-of-type > i {
  width: 4px;
  height: 4px;
  background-color: #ccc;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

/* for the popovers */
.ant-popover .ant-popover-content .ant-popover-arrow {
  border-top-color: rgba(17, 48, 73, 0.95);
  border-right-color: rgba(17, 48, 73, 0.95);
  border-bottom-color: rgba(17, 48, 73, 0.95);
  border-left-color: rgba(17, 48, 73, 0.95);
}

.ant-popover .ant-popover-content .ant-popover-inner {
  background: rgba(17, 48, 73, 0.95);
}

.ant-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  color: white;
  border-bottom: 1px solid rgba(196, 205, 212, 0.2);
}

.ant-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  color: white;
}

/* 
block-left
*/
.block-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.block-left {
  flex: 1 1 125px;
  text-align: left;
}

.block-left .route-icon {
  position: relative;
  text-align: center;
}

.block-truck.active {
  /* background-color: #31b336 !important; */
}

.block-left .line > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-left .line > i:first-of-type {
  /* border: 1px solid #31b336 !important; */
}

.block-left .line > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.selected-port {
  /* background-color: #008ced !important; */
}

/* .selected-port:hover {
  background: attr(data-color) !important;
} */
.block-left .port-line .port-right {
  position: absolute;
  bottom: -15px;
  right: -53px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #43637c;
}

/* 
block-center
*/
.block-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  /* margin-bottom: -10px; */
}

.block-center {
  /* flex: 1 1 150px; */
  flex: 1 1 125px;
  text-align: center;
}

.block-center .route-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

/* 
block-right
*/

.block-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.block-right {
  flex: 1 1 125px;
  text-align: right;
}

.block-right .route-icon {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.block-right .block-truck.active {
  /* background-color: #31b336 !important; */
}

.block-right .line > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right .line > i:first-of-type {
  /* border: 1px solid #008ced !important; */
}

.block-right .line > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right .line > i:last-of-type {
  /* border: 1px solid #31b336 !important; */
}

.block-right .selected-port {
  /* background-color: #008ced !important; */
}

.block-right .port-line .port {
  position: absolute;
  bottom: -15px;
  left: -53px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #43637c;
}


/* Modify individual block layout to stack vertically */
.block-left-small-screen, .block-center-small-screen, .block-right-small-screen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.block-left-small-screen .block-center-small-screen .block-right-small-screen{
  flex: 1 1 125px;  /* Allows the element to grow/shrink vertically, with 125px as its starting height */
  text-align: left;
}

.block-left-small-screen .route-icon > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-left-small-screen .route-icon > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}


.block-center-small-screen .route-icon > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-center-small-screen .route-icon > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right-small-screen .route-icon > i:first-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  left: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-right-small-screen .route-icon > i:last-of-type {
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: absolute;
  top: -7px;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.block-left-small-screen .port-line .port-right {
  width: 120px;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #43637c;
}


.block-left-small-screen .block-right-small-screen {
  position: relative;
  text-align: left;
  margin-bottom: 10px;
}

.block-left-small-screen .route-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.block-center-small-screen .route-icon{
  position: relative;
  text-align: center;
}

.block-right-small-screen .route-icon{
  position: relative;
  text-align: center;
}

.block-left-small-screen .line, .block-center-small-screen .line, .block-right-small-screen .line {
  height: 100%;
  width: 1px;
  min-height: 70px;
  background-color: #ccc;
  margin-left: 5px;
}

/* 
xyz
*/
.additional-options {
  padding: 0 20px 12px 20px;
  /* border-right: 1px solid #eff3f7; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.map-viewdetails {
  color: #008cde;
  width: 100%;
  max-width: '105px';
  height: '20px';
}

.map-viewdetails:hover {
  cursor: pointer;
  color: black;
}

.map-viewdetails-none {
  width: 100%;
  max-width: '105px';
  height: '20px';
  cursor: default;
}

/***********Start of Right block in Top section*****************/
/* book, weight,volume, types container */

/* .block-items-right {
    width: 203px;
    height: 228px;
    padding: 0px;
} */

.cost-sealine {
  padding: 10px 0 5px 20px;
  border-left: 1px solid #eff3f7;
}

.size-block {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid #eff3f7;
  border-bottom: 1px solid #eff3f7;
  min-height: 47px;
  /* width: 100%; */
  border-left: 1px solid #eff3f7;
}

.size-block li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  /* line-height: normal; */
}

.size-block li span:nth-child(1) {
  flex-grow: 1;
}

.booknow-savequote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eff3f7;
}

.savequote-container {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-savequote:hover {
  background-color: #f0fafe;
  border: 1px solid #aad8ff;
}

/* **********end of TOP section************** */

/* **********start of TOGGLE Buttom section************** */
/* map and view details toggle */
.block-items-footer {
  padding: 0px;
  width: 100%;
  max-width: 655px;
  height: auto;
}

.detailed-list {
  /* padding: 20px; */
  /* font-size: 12px; */
}

.bottom-view-details {
  font-size: 12px;
}

/* .bottom-view-details div {
  border-bottom: 2px solid transparent;
}

.bottom-view-details div:last-child {
  border-bottom: 0px solid transparent;
} */

.bottom-view-details > .bottom-view-decor {
  border-bottom: 2px solid transparent;
}

/* Pick up / delivery*/
/* Port of origin */
/* Ocean Freight */
/* Port of discharge/PortOfDischargeTable */
.my-table-21a3741 .ant-table table {
  text-align: center;
  border-radius: 0px 0px 0px 0px;
  border-collapse: collapse;
}

.my-table-21a3741 .ant-table-content > .ant-table-body > table {
  /* border: 0px; */
}

.my-table-21a3741 .ant-table-body .ant-table-thead > tr th {
  background: white;
  font-size: 10px;
  color: #43637c;
  height: 30px;
  padding: 0px;
}

.my-table-21a3741 .ant-table-thead > tr:first-child > th:last-child {
  border-radius: 0px;
}

.my-table-21a3741
  .ant-table-body
  .ant-table-thead
  > tr
  th
  > span[class='ant-table-header-column'] {
  /* color: black; */
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4px;
  margin-left: 4px;
  /* margin-left: -20px; */
}

.my-table-21a3741 .ant-table-body .ant-table-tbody tr td {
  /* color: black; */
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  font-size: 12px;
  color: #43637c;
  height: 22px;
  padding: 4px;
}

/* align price table data cells left */
.my-table-21a3741 th.column-price,
td.column-price {
  text-align: left !important;
}

/* remove left border */

.my-table-21a3741 .ant-table-bordered .ant-table-body > table {
  border-left: 0px;
}

/* Delivery */

/* **********end of buttom************** */

/* *********************end of LEFT********************* */

/* *********************start of RIGHTEST****************** */
/* right variable arrow block */
.arrow-block {
  width: 20px;
  min-height: 100%;
  padding: 0px 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  background-color: #eaf9ff;
}

.arrow-block:hover {
  background-color: #d4f3ff;
  opacity: 1;
}

/* *********************end of RIGHTEST******************** */
